import flatpickr from "flatpickr";
import Holidays from "date-holidays";
import weekSelect from "./utils/weekSelect";

const pickers = {};

function addClearButton(element, pickerInstance) {
  if (!element.hasAttribute("required")) {
    const container = element.closest(".controls");
    if (container === null) return;
    const clearButton = document.createElement("button");
    clearButton.innerHTML = '<i class="fa fa-xmark"></i>';
    clearButton.type = "button";
    clearButton.className = "btn btn-date-clear";
    clearButton.addEventListener("click", function () {
      pickerInstance.clear();
    });
    container.appendChild(clearButton);
  }
}

function firstDayOfWeek(dateObject, firstDayOfWeekIndex) {
  const dayOfWeek = dateObject.getDay(),
    firstDayOfWeek = new Date(dateObject),
    diff =
      dayOfWeek >= firstDayOfWeekIndex
        ? dayOfWeek - firstDayOfWeekIndex
        : 6 - dayOfWeek;

  firstDayOfWeek.setDate(dateObject.getDate() - diff);
  firstDayOfWeek.setHours(0, 0, 0, 0);

  return firstDayOfWeek;
}

function lastDayOfTheWeek(dateObject, lastDayOfWeekIndex) {
  const dayOfWeek = dateObject.getDay(),
    lastDay = new Date(dateObject),
    diff = (lastDayOfWeekIndex + (7 - dateObject.getDay())) % 7;
  lastDay.setDate(dateObject.getDate() + diff);
  lastDay.setHours(0, 0, 0, 0);
  return lastDay;
}

export function initializeDatePicker(element, country, extras) {
  let holidays = new Holidays(country, { types: ["public"] });
  const instance = flatpickr(element, {
    altFormat: extras.dateFormat,
    altInput: true,
    allowInput: true,
    onDayCreate: function (dObj, dStr, fp, dayElem) {
      holidays
        .getHolidays(dayElem.dateObj.getFullYear())
        .forEach(function (element) {
          const elemDate = new Date(dayElem.attributes[1].value);
          const holyDate = new Date(element.date);
          if (elemDate.getTime() === holyDate.getTime())
            dayElem.className += " higlighted-holiday";
        });
    },
    onChange: function (selectedDates, dateStr, instance) {
      if (element.dataset.endDate) {
        pickers[`id_${element.dataset.endDate}`].set(
          "minDate",
          selectedDates[0],
        );
      }

      if (element.dataset.startDate) {
        pickers[`id_${element.dataset.startDate}`].set(
          "maxDate",
          selectedDates[0],
        );
      }
    },
    onOpen: function (selectedDates, dateStr, instance) {
      this.altInput.readOnly = true;
      if (element.dataset.endDate) {
        this.set(
          "maxDate",
          pickers[`id_${element.dataset.endDate}`].selectedDates[0],
        );
      }

      if (element.dataset.startDate) {
        this.set(
          "minDate",
          pickers[`id_${element.dataset.startDate}`].selectedDates[0],
        );
      }
    },
    onClose: function (selectedDates, dateStr, instance) {
      this.altInput.readOnly = false;
      this.altInput.blur();
    },
  });
  pickers[element.id] = instance;
  addClearButton(element, instance);
}

export function initializeDateRangePicker(element, extras) {
  const instance = flatpickr(element, {
    mode: "range",
    altFormat: extras.dateFormat,
    onClose: (selectedDates, dateStr, instance) => {
      if (selectedDates.length === 1) {
        instance.setDate.push(selectedDates[0]);
      }
    },
    altInput: true,
  });
  pickers[element.id] = instance;
  addClearButton(element, instance);
}

export function initializeTimePicker(element, extras) {
  const instance = flatpickr(element, {
    enableTime: true,
    noCalendar: true,
    dateFormat: "H:i",
    altFormat: extras.timeFormat,
    altInput: true,
    time_24hr: extras.timeFormat === "H:i",
    onChange: function (selectedDates, dateStr, instance) {
      if (element.dataset.endTime) {
        pickers[`id_${element.dataset.endTime}`].set(
          "minTime",
          selectedDates[0],
        );
      }

      if (element.dataset.startTime) {
        pickers[`id_${element.dataset.startTime}`].set(
          "maxTime",
          selectedDates[0],
        );
      }
    },
  });
  pickers[element.id] = instance;
  addClearButton(element, instance);
}

export function initializeDateTimePicker(element, extras) {
  const instance = flatpickr(element, {
    enableTime: true,
    altFormat: "{extras.dateFormat} {extras.timeFormat}",
    altInput: true,
    time_24hr: extras.timeFormat === "H:i",
  });
  pickers[element.id] = instance;
  addClearButton(element, instance);
}

export function initializeDateTimeFields(element, extras) {
  element.querySelectorAll(".dateinput").forEach((element) => {
    let country = "";
    try {
      country = document.getElementById("country-code").value;
      const destinationAddress = document.getElementById(
        "id_destination_address",
      );
      const addressAPI = document.getElementById("address-api").value;
      destinationAddress.addEventListener("change", async function () {
        const response = await fetch(
          addressAPI.replace("0", destinationAddress.firstChild.value),
        );
        const item = await response.json();
        country = item["country_code"];
        initializeDatePicker(element, country, extras);
      });
    } catch (error) {
      country = document.getElementById("country-code").value;
    } finally {
      initializeDatePicker(element, country, extras);
    }
  });

  element.querySelectorAll(".daterangeinput").forEach((element) => {
    initializeDateRangePicker(element, extras);
  });

  element.querySelectorAll(".timeinput").forEach((element) => {
    initializeTimePicker(element, extras);
  });

  element.querySelectorAll(".datetimeinput").forEach((element) => {
    initializeDateTimePicker(element, extras);
  });
}

export function initializeDashboardDatePicker(element, country, extras) {
  let holidays = new Holidays(country, { types: ["public"] });
  const instance = flatpickr(element, {
    mode: "range",
    altFormat: extras.dateFormat,
    altInput: true,
    defaultDate: "today",
    onDayCreate: function (dObj, dStr, fp, dayElem) {
      holidays
        .getHolidays(dayElem.dateObj.getFullYear())
        .forEach(function (element) {
          const elemDate = new Date(dayElem.attributes[1].value);
          const holyDate = new Date(element.date);
          if (elemDate.getTime() === holyDate.getTime())
            dayElem.className += " higlighted-holiday";
        });
    },
  });
  return instance;
}

export function initializeDashboardDateRangePicker(element, extras) {
  const instance = flatpickr(element, {
    mode: "range",
    dateFormat: extras.dateFormat,
    altFormat: extras.dateFormat,
    altInput: true,
    defaultDate: [
      firstDayOfWeek(new Date(), 1),
      lastDayOfTheWeek(new Date(), 7),
    ],
    locale: {
      firstDayOfWeek: 1,
    },
    plugins: [new weekSelect({})],
  });
  return instance;
}

export function initializeAllDayCheckbox(element) {
  element.querySelectorAll(".time-range-container").forEach((container) => {
    const checkbox = container.querySelector('input[type="checkbox"]');
    const inputs = container.querySelectorAll(".timeinput.flatpickr-input");
    if (inputs.length !== 2) {
      checkbox.closest(".checkbox-container").remove();
      return;
    }
    const fromInput = inputs[0];
    const toInput = inputs[1];

    if (checkbox.checked) {
      pickers[fromInput.id]._input.setAttribute("disabled", "disabled");
      pickers[toInput.id]._input.setAttribute("disabled", "disabled");
    }

    checkbox.addEventListener("change", function (e) {
      if (e.target.checked) {
        pickers[fromInput.id].setDate(null);
        pickers[toInput.id].setDate(null);
        pickers[fromInput.id]._input.setAttribute("disabled", "disabled");
        pickers[toInput.id]._input.setAttribute("disabled", "disabled");
      } else {
        pickers[fromInput.id]._input.removeAttribute("disabled");
        pickers[toInput.id]._input.removeAttribute("disabled");
      }
    });
  });
}

document.addEventListener("projectsInitialized", async function () {
  initializeDateTimeFields(document, dataTimeFieldFormats);
  initializeAllDayCheckbox(document);

  for (const id in pickers) {
    if (pickers[id].selectedDates.length < 1) continue;
    if (pickers[id].element.dataset.endDate) {
      pickers[`id_${pickers[id].element.dataset.endDate}`].set(
        "minDate",
        pickers[id].selectedDates[0],
      );
    }

    if (pickers[id].element.dataset.startDate) {
      pickers[`id_${pickers[id].element.dataset.startDate}`].set(
        "maxDate",
        pickers[id].selectedDates[0],
      );
    }

    if (pickers[id].element.dataset.endTime) {
      pickers[`id_${pickers[id].element.dataset.endTime}`].set(
        "minTime",
        pickers[id].selectedDates[0],
      );
    }

    if (pickers[id].element.dataset.startTime) {
      pickers[`id_${pickers[id].element.dataset.startTime}`].set(
        "maxTime",
        pickers[id].selectedDates[0],
      );
    }
  }
});

export function parseDate(dateStr, dateFormat) {
  return flatpickr.parseDate(dateStr, dateFormat);
}

export function formatDate(dateObj, dateFormat) {
  return flatpickr.formatDate(dateObj, dateFormat);
}

export default {
  initializeDatePicker,
  initializeTimePicker,
  initializeDateRangePicker,
  initializeDateTimePicker,
  initializeDateTimeFields,
  initializeDashboardDatePicker,
  initializeDashboardDateRangePicker,
  parseDate,
  formatDate,
};
